import { HydrateAtoms } from '@components/hydrate-atoms';
import { featureFlagsAtom } from '@feature-flags/atoms';
import * as ExperimentActions from '@redux/experiments';
import { selectAssignment } from '@redux/experiments/selectors';
import { betterImageAssignmentSelector } from '@redux/experiments/selectors/better-image';
import { expectedValueSortSelector } from '@redux/experiments/selectors/expected-value-sort';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { newHeartAssignmentSelector } from '@redux/experiments/selectors/new-heart';
import { newSortPillAssignmentSelector } from '@redux/experiments/selectors/new-sort-pill';
import { newStartingPriceAssignmentSelector } from '@redux/experiments/selectors/new-starting-price';
import { quickResponderAssignmentSelector } from '@redux/experiments/selectors/quick-responder';
import { semanticSearchAssignmentSelector } from '@redux/experiments/selectors/semantic-search';
import { y1IVOLv2AssignmentSelector } from '@redux/experiments/selectors/y1-ivol-v2';
import { useAppSelector } from '@redux/hooks';
import { experiments, tkHost } from '@settings';
import { Provider as JotaiProvider } from 'jotai';
import { useAtomValue } from 'jotai';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import buildLinkedDataFromApiData from '../../../helpers/linkedData';
import { ExpectedValueSortAssignment } from '../../../types/experiments/variants';
import MetaContent from '../../components/shared/HelmetHeader/HelmetHeader';
import { RFQModalContextProvider } from '../../contexts/RFQModalContext/RFQModalContext';
import { WeddingsContextProvider } from '../../contexts/WeddingsContext/WeddingsContext';
import { setCookieLocation } from '../../utils/cookie/location';
import WrappedVendorsSearch from './containers/WrappedVendorsSearch';
import * as util from './util';
import {
	isRenameEstateVenuesFacetExperimentEnabled,
	seoTextVariantListingEstate,
} from './utils/renameEstateVenuesFacetExperiment';
import {
	isRenameGardenVenuesFacetExperimentEnabled,
	seoTextVariantListingGarden,
} from './utils/renameGardenVenuesFacetExperiment';
import {
	getSeoTextVariantListing,
	isRenameHistoricVenuesFacetExperimentEnabled,
} from './utils/renameHistoricVenuesFacetExperiment';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & RouteComponentProps;

const VendorsSearchContainer: FunctionComponent<Props> = (props) => {
	const {
		category,
		categoryFilters,
		vendorLocation,
		history: {
			location: { pathname },
		},
		isPermitted,
		reportServerSideExperiment,
		seo,
	} = props;

	const currentFeatureFlags = useAtomValue(featureFlagsAtom);

	let seoCategory = util.categoryText(category, categoryFilters);
	if (
		isRenameHistoricVenuesFacetExperimentEnabled(
			seoCategory,
			vendorLocation.stateCode,
		)
	) {
		seoCategory = getSeoTextVariantListing();
	} else if (
		isRenameGardenVenuesFacetExperimentEnabled(
			seoCategory,
			vendorLocation.stateCode,
		)
	) {
		seoCategory = seoTextVariantListingGarden;
	} else if (
		isRenameEstateVenuesFacetExperimentEnabled(
			seoCategory,
			vendorLocation.stateCode,
		)
	) {
		seoCategory = seoTextVariantListingEstate;
	}

	const reorderAllFiltersAssignment = useAppSelector((state) =>
		selectAssignment(state, ['reorderAllFilters']),
	);
	const tkNoContentBlurbsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['tkNoContentBlurbs']),
	);
	const refreshResultsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['refreshResults']),
	);

	const betterImageAssignment = useAppSelector((state) =>
		betterImageAssignmentSelector(state),
	);

	const newHeartAssignment = useAppSelector((state) =>
		newHeartAssignmentSelector(state),
	);

	const mobileMapViewAssignment = useAppSelector((state) =>
		mobileMapViewAssignmentSelector(state),
	);

	const quickResponderAssignment = useAppSelector((state) =>
		quickResponderAssignmentSelector(state),
	);

	const semanticSearchAssignment = useAppSelector((state) =>
		semanticSearchAssignmentSelector(state),
	);

	const newSortPillAssignment = useAppSelector((state) =>
		newSortPillAssignmentSelector(state),
	);

	const y1IVOLv2Assignment = useAppSelector((state) =>
		y1IVOLv2AssignmentSelector(state),
	);

	const newStartingPriceAssignment = useAppSelector((state) =>
		newStartingPriceAssignmentSelector(state),
	);

	const expectedValueSortAssignment = useAppSelector((state) =>
		expectedValueSortSelector(state),
	);

	useEffect(() => {
		window.UnionConsentManagement.onConsentedToPersonalisation(() => {
			setCookieLocation(vendorLocation);
		});

		if (reorderAllFiltersAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Reorder all filters Assignment: ',
				reorderAllFiltersAssignment,
			);
			reportServerSideExperiment(experiments.reorderAllFilters);
		}

		if (tkNoContentBlurbsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'TK No Content Blurbs Assignment: ',
				tkNoContentBlurbsAssignment,
			);
			reportServerSideExperiment(experiments.tkNoContentBlurbs);
		}

		if (refreshResultsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Refresh results Assignment: ', refreshResultsAssignment);
			reportServerSideExperiment(experiments.refreshResults);
		}

		if (betterImageAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Better Image Assignment: ', betterImageAssignment);
			reportServerSideExperiment(experiments.betterImage);
		}

		if (newHeartAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('New Heart Assignment: ', newHeartAssignment);
			reportServerSideExperiment(experiments.newHeart);
		}

		if (mobileMapViewAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Mobile Map View Assignment: ', mobileMapViewAssignment);
			reportServerSideExperiment(experiments.mobileMapView);
		}

		if (quickResponderAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Quick Responder Assignment: ', quickResponderAssignment);
			reportServerSideExperiment(experiments.quickResponder);
		}

		if (semanticSearchAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Semantic Search Assignment: ', semanticSearchAssignment);
			reportServerSideExperiment(experiments.semanticSearch);
		}

		if (newSortPillAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('New Sort Pill Assignment: ', newSortPillAssignment);
			reportServerSideExperiment(experiments.sortPill);
		}

		if (y1IVOLv2Assignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Y1 IVOL VERSION 2 View Assignment: ', y1IVOLv2Assignment);
			reportServerSideExperiment(experiments.y1IVOLv2);
		}

		if (newStartingPriceAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'New starting price assignment: ',
				newStartingPriceAssignment,
			);
			reportServerSideExperiment(experiments.newStartingPrice);
		}

		if (expectedValueSortAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Expected value sort assignment: ',
				expectedValueSortAssignment,
			);
			reportServerSideExperiment(experiments.expectedValue);
		}
	}, [
		betterImageAssignment,
		mobileMapViewAssignment,
		newHeartAssignment,
		refreshResultsAssignment,
		reorderAllFiltersAssignment,
		reportServerSideExperiment,
		tkNoContentBlurbsAssignment,
		vendorLocation,
		quickResponderAssignment,
		semanticSearchAssignment,
		newSortPillAssignment,
		y1IVOLv2Assignment,
		newStartingPriceAssignment,
		expectedValueSortAssignment,
	]);

	return (
		<>
			<RFQModalContextProvider>
				<WeddingsContextProvider>
					<MetaContent
						buildLinkedData={buildLinkedDataFromApiData}
						canonical={seo.canonicalUrl}
						description={`Learn more about ${seoCategory.toLowerCase()} in ${
							vendorLocation.city
						} on The Knot.
            Find, research and contact wedding professionals on The Knot, featuring reviews
            and info on the best wedding vendors.`}
						landingPage="listings"
						next={seo.nextUrl}
						ogImage={util.ogImage(category.code)}
						pathname={`${tkHost}${pathname}`}
						prev={seo.previousUrl}
						title={util.pageTitle(vendorLocation, seoCategory)}
						noIndex={!isPermitted}
					/>
					<JotaiProvider>
						<HydrateAtoms
							initialValues={[[featureFlagsAtom, currentFeatureFlags]]}
						>
							<WrappedVendorsSearch />
						</HydrateAtoms>
					</JotaiProvider>
				</WeddingsContextProvider>
			</RFQModalContextProvider>
		</>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	category: state.category,
	categoryFilters: state.filters.categoryFilters,
	isPermitted: state.search.isPermitted,
	seo: state.seo,
	vendorLocation: state.location,
});

export const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const enhance = compose(
	connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhance(VendorsSearchContainer);
