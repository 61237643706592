import Icon from '@xo-union/tk-component-icons';
import { Body1 } from '@xo-union/tk-ui-typography';
import PropTypes from 'prop-types';
import React from 'react';
import Styles from '../styles.scss';

const QaPair = (props) => {
	const { caret, isMobile, idx, item, setCaret } = props;

	return (
		<div className={Styles.data} key={item.question}>
			<div
				className={Styles.iconSplit}
				onClick={(e) => {
					e.preventDefault();
					if (isMobile) {
						const newCaret = [...caret];
						newCaret[idx].name =
							newCaret[idx].name === 'caret_up' ? 'caret_down' : 'caret_up';
						newCaret[idx].isOpen = !newCaret[idx].isOpen;
						setCaret(newCaret);
					}
				}}
			>
				{caret[idx] && (
					<Icon
						className={Styles.icon}
						name={caret[idx].name}
						size="md"
						data-testid={`caret_${idx}_${caret[idx].name}`}
					/>
				)}
				<Body1 bold className={Styles.question}>{`Q: ${item.question}`}</Body1>
			</div>
			{!isMobile && (
				<Body1 className={Styles.answer}>{`A: ${item.answer}`}</Body1>
			)}
			{isMobile && caret[idx] && caret[idx].isOpen && (
				<Body1 className={Styles.answer}>{`A: ${item.answer}`}</Body1>
			)}
		</div>
	);
};

QaPair.propTypes = {
	caret: PropTypes.arrayOf(PropTypes.object).isRequired,
	idx: PropTypes.number.isRequired,
	isMobile: PropTypes.bool.isRequired,
	item: PropTypes.shape({
		question: PropTypes.string,
		answer: PropTypes.string,
	}).isRequired,
	setCaret: PropTypes.func.isRequired,
};

export default QaPair;
