import { FAQ } from '@typings/faq';
import { Row } from '@xo-union/tk-component-grid';
import { H4 } from '@xo-union/tk-ui-typography';
import React, { useEffect, useState, FC } from 'react';
import { connect } from 'react-redux';
import QaPair from './qaPair';
import Styles from './styles.scss';

interface Caret {
	isOpen: boolean;
	name: string;
}

interface DynamicFAQProps {
	data: FAQ[];
	isMobile: boolean;
}

const DynamicFAQ: FC<DynamicFAQProps> = (props) => {
	const { data, isMobile } = props;
	const [caret, setCaret] = useState<Caret[]>([]);

	useEffect(() => {
		if (data?.length && data.length !== caret.length) {
			const iterateData = data.map(() => ({
				name: 'caret_down',
				isOpen: false,
			}));
			setCaret(iterateData);
		}
	}, [data?.length]);

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<Row>
			<div className={Styles.wrapper}>
				<div className={isMobile ? Styles.faqBodyMobile : Styles.faqBody}>
					<H4 className={Styles.title}>Frequently Asked Questions</H4>
					<div className={Styles.dataUnit}>
						{data.map((item, idx) => (
							<QaPair
								key={item.question}
								caret={caret}
								idx={idx}
								isMobile={isMobile}
								item={item}
								setCaret={setCaret}
								data-testid="qa-pair"
							/>
						))}
					</div>
				</div>
			</div>
		</Row>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	data: state.faq.data,
	isMobile: state.viewport.isMobile,
});

export default connect(mapStateToProps)(DynamicFAQ);
