import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { Link } from '@xo-union/tk-component-buttons';
import React, { FC, useCallback } from 'react';
import AnalyticsEvents from '../../../../../marketplace-web/constants/analytics';
import Styles from './VenueFacetLinks.styles.scss';

export type FacetLinkProps = {
	href: string;
	isLastItem: boolean;
	label: string;
};

export const FacetLink: FC<FacetLinkProps> = ({ href, isLastItem, label }) => {
	const { track } = useAnalyticsContext();
	const trackLinkClicked = useCallback(() => {
		const properties = {
			sourcePage: 'category results',
			sourceContent: 'venue_facet_links',
			selection: [label.toLowerCase()],
		};

		track({
			event: AnalyticsEvents.LINK_CLICKED,
			properties,
		});
	}, [track, label]);

	return (
		<>
			<Link
				className={Styles.link}
				color="tertiary-alternative"
				href={href}
				size="lg"
				onClick={trackLinkClicked}
			>
				{label}
			</Link>
			{!isLastItem && (
				<span className={Styles.dot} data-testid="dot">
					&#8226;
				</span>
			)}
		</>
	);
};
