import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import { H3 } from '@xo-union/tk-ui-typography';
import React from 'react';
import { FacetLink } from './FacetLink';
import Styles from './VenueFacetLinks.styles.scss';

type FacetLinkType = {
	label: string;
	facet: string;
};

const facetLinks: FacetLinkType[] = [
	{ label: 'Affordable', facet: 'price_range=affordable' },
	{ label: 'Backyard', facet: 'settings=backyard' },
	{ label: 'Ballroom', facet: 'settings=ballroom' },
	{ label: 'Barn', facet: 'settings=barn' },
	{ label: 'Beach', facet: 'settings=beach' },
	{ label: 'Brewery', facet: 'settings=brewery' },
	{ label: 'Castle', facet: 'settings=castle' },
	{ label: 'City Hall', facet: 'settings=city-hall' },
	{ label: 'Country Club', facet: 'settings=country-club' },
	{ label: 'Estate', facet: 'settings=estate' },
	{ label: 'Forest', facet: 'settings=forest' },
	{ label: 'Garden', facet: 'settings=garden' },
	{ label: 'Historic Venue', facet: 'settings=historic-venues' },
	{ label: 'Hotel', facet: 'settings=hotel-venue' },
	{ label: 'Inexpensive', facet: 'price_range=inexpensive' },
	{ label: 'Library', facet: 'settings=library' },
	{ label: 'Loft', facet: 'settings=loft' },
	{ label: 'Luxury', facet: 'price_range=luxury' },
	{ label: 'Mountain', facet: 'settings=mountain' },
	{ label: 'Museum', facet: 'settings=museum' },
	{
		label: 'Outdoor',
		facet:
			'outdoor_space=uncovered-outdoor-wedding-reception-site+covered-outdoor-wedding-reception-site',
	},
	{ label: 'Park', facet: 'settings=park' },
	{ label: 'Religious', facet: 'settings=religious' },
	{ label: 'Restaurant', facet: 'settings=restaurant' },
	{ label: 'Rooftop', facet: 'settings=rooftop' },
	{ label: 'Small', facet: 'guest_capacity=0-50' },
	{ label: 'Tented', facet: 'settings=tented' },
	{ label: 'Vineyard', facet: 'settings=vineyard' },
];

export const buildBase = (path: string) => {
	const splitPath = path.split('/');

	if (splitPath?.[1] && splitPath?.[2]) {
		return `/${splitPath[1]}/${splitPath[2]}`;
	}

	return '';
};

export const VenueFacetLinks = () => {
	const city = useAppSelector((state) => state.location.city);
	const sort = useAppSelector((state) => state.search.sort);
	const path = useAppSelector((state) => state.requestInfo.path);
	const categoryCode = useAppSelector((state) => state.category.code);
	const sortSlug = sort ? `&sort=${sort}` : '';
	const base = buildBase(path);

	if (categoryCode !== 'REC' || !base) {
		return null;
	}
	return (
		<>
			<Row>
				<Column xs="12">
					<H3 className={Styles.heading}>Explore other venues in {city}</H3>
				</Column>
			</Row>
			<Row>
				<Column xs="12" className={Styles.links}>
					{facetLinks.map((link, index) => (
						<FacetLink
							key={link.facet}
							label={link.label}
							href={`${base}?${link.facet}${sortSlug}`}
							isLastItem={index === facetLinks.length - 1}
						/>
					))}
				</Column>
			</Row>
		</>
	);
};
