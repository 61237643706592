export function updateSeoFilterValue(
	seoFilter = '',
): Seo.UpdateSeoFilterValueAction {
	return {
		type: 'UPDATE_SEO_FILTER_VALUE',
		payload: {
			seoFilter,
		},
	};
}

export function updateCanonicalUrls(
	urls: Seo.CanonicalUrls,
): Seo.UpdateCanonicalUrlsAction {
	return {
		type: 'UPDATE_SEO_CANONICAL_URLS',
		payload: urls,
	};
}
