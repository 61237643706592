import { fetchSearchResultsError } from '@redux/search';
import type { ErrorPayload } from 'types/api';
import type { SortType } from 'types/search';
import type { Actions } from 'types/search/actionTypes';
import { fetchBase } from '../../../../actions/search';
import constants from '../../constants';

export function fetchSearchResults(options = {}) {
	return fetchBase(constants.FETCH_SEARCH_RESULTS, options);
}

// Will only be called for server side rendering.
export function updateSearchResultsFromCache(
	response: (API.SuccessfulResponse<API.SearchResponseData> &
		Actions.SearchResultsPayload) &
		ErrorPayload,
): Actions.FetchSearchResultsSuccess | Actions.FetchSearchResultsError {
	if (response.errors) {
		return fetchSearchResultsError([
			new Error(response.errors[0].message || 'Unknown'),
		]);
	}

	return {
		type: `${constants.FETCH_SEARCH_RESULTS}_SUCCESS`,
		payload: response,
	};
}

export function updatePage(page: number) {
	return {
		type: constants.UPDATE_PAGE,
		payload: { page },
	};
}

export function updateSort(sort: SortType) {
	return {
		type: constants.UPDATE_SORT,
		payload: { sort },
	};
}
