import type { ChangeLocation } from 'types/location/actionTypes';
import constants from '../../constants';

export function changeLocation(locationHash: {
	city: string;
	stateCode: string;
}): ChangeLocation {
	return {
		type: constants.CHANGE_LOCATION,
		location: locationHash,
	};
}
