export const clearFilters = (
	filters: Filters.AppliedFilters,
): Filters.ClearFiltersAction => {
	const clearedFilters: Filters.AppliedFilters = {};
	Object.keys(filters).forEach((filter) => {
		clearedFilters[filter] = [];
	});

	return {
		type: 'filters/CLEAR_FILTERS',
		payload: {
			filters: clearedFilters,
		},
	};
};

export const updateCategoryFilter = (
	categoryFilters: Filters.AppliedFilter,
): Filters.UpdateCategoryFilterAction => ({
	type: 'filters/UPDATE_CATEGORY_FILTER',
	payload: categoryFilters,
});

export const updateMultiFilter = (
	checked: boolean,
	value: string,
	id: string,
	category: string,
	values: Filters.AppliedFilter[],
): Filters.UpdateMultiFilterAction => {
	let updatedValues = [];
	if (checked) {
		updatedValues = [...values, { value, id }];
	} else {
		updatedValues = values.filter((val) => val.value !== value);
	}
	return {
		type: 'filters/CHANGE_MULTI_FILTER',
		payload: {
			category,
			values: updatedValues,
		},
	};
};

export const updateSingleFilter = (
	value: string,
	id: string,
	category: string,
): Filters.UpdateSingleFilterAction => ({
	type: 'filters/CHANGE_SINGLE_FILTER',
	payload: {
		category,
		values: [{ value, id }],
	},
});
