import { Row } from '@xo-union/tk-component-grid';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { calculateFiltersCount } from '../../utils';
import Styles from './styles.scss';

export const PageDescription = ({ description, filters, page }) => {
	const filterCount = calculateFiltersCount(filters); // ?

	if (filterCount !== 0 || description === '' || page !== 1) {
		return null;
	}

	return (
		<Row>
			<div className={Styles.wrapper}>
				<div
					className={Styles.descriptionContainer}
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			</div>
		</Row>
	);
};

PageDescription.propTypes = {
	category: PropTypes.shape({
		name: PropTypes.string,
	}).isRequired,
	description: PropTypes.string.isRequired,
	filters: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string,
				value: PropTypes.string,
			}),
		),
	).isRequired,
	location: PropTypes.shape({
		city: PropTypes.string,
	}).isRequired,
	page: PropTypes.number.isRequired,
};

export function mapStateToProps(state) {
	const page =
		state.search && state.search.pagination && state.search.pagination.page;
	return {
		category: state.category,
		description: state.search.searchCopy,
		filters: state.filters.categoryFilters,
		location: state.location,
		page,
	};
}

export default connect(mapStateToProps)(PageDescription);
